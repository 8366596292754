// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/cart/advanced/BiosonahAmountAdjuser.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/cart/advanced/BiosonahAmountAdjuser.tsx");
  import.meta.hot.lastModified = "1736334486000";
}
// REMIX HMR END

import { useNavigate } from '@remix-run/react';
import React, { useEffect, useState } from 'react';
import { addItemToOrder } from '~/providers/external/orders/order';
export const BiosonahAmountAdjuster = ({
  customer,
  product,
  order,
  refreshOrders,
  size = 'large'
}) => {
  _s();
  const [quantityInput, setQuantityInput] = useState('0');
  const [typingTimeout, setTypingTimeout] = useState(null);
  const navigate = useNavigate();
  const formatQuantity = quantity => {
    return product.unit === 'KG' ? quantity.toFixed(3) : quantity.toString();
  };
  const checkCustomer = () => {
    if (!customer) return navigate('/sign-in');
  };
  const handleIncrease = async () => {
    checkCustomer();
    const increment = product.unit === 'KG' ? product.increaseBy / 1000 : 1;
    const newQuantity = order ? order.quantity + increment : increment;
    await addItemToOrder(customer.id, null, null, String(product.variantId), newQuantity, product.unit);
    refreshOrders();
  };
  const handleDecrease = async () => {
    checkCustomer();
    const decrement = product.unit === 'KG' ? product.increaseBy / 1000 : 1;
    const newQuantity = order ? order.quantity - decrement : 0;
    await addItemToOrder(customer.id, null, null, String(product.variantId), newQuantity, product.unit);
    refreshOrders();
  };
  const updateQuantity = async val => {
    if (isNaN(val) || val < 0) return;
    const newQuantity = val;
    await addItemToOrder(customer.id, null, null, String(product.variantId), newQuantity, product.unit);
    refreshOrders();
  };
  const handleInputChange = e => {
    const newVal = e.target.value;
    setQuantityInput(newVal);
    if (typingTimeout) clearTimeout(typingTimeout);
    setTypingTimeout(setTimeout(() => {
      updateQuantity(Number(newVal));
    }, 500));
  };
  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      if (typingTimeout) clearTimeout(typingTimeout);
      updateQuantity(Number(quantityInput));
    }
  };
  useEffect(() => {
    setQuantityInput(order ? order.quantity : '0');
  }, [order]);
  return <div className="input-and-checkbox-container">
      <div className={`input-group${size === 'small' ? '-inCart' : ''}`}>
        <button type="button" onClick={handleDecrease}>
          -
        </button>
        <input type="number" step={product.unit === 'KG' ? '0.010' : '1'} min="0" value={formatQuantity(Number(quantityInput))} onChange={handleInputChange} onKeyDown={handleKeyPress} onClick={checkCustomer} className={`${!customer ? 'cursor-not-allowed' : ''}`} />
        {size === 'large' && <div className="quantity-text"> {product.unit}</div>}
        <button type="button" onClick={handleIncrease}>
          +
        </button>
      </div>
    </div>;
};
_s(BiosonahAmountAdjuster, "VEghGeEE09+LuuDkhYiL5aWkjdE=", false, function () {
  return [useNavigate];
});
_c = BiosonahAmountAdjuster;
var _c;
$RefreshReg$(_c, "BiosonahAmountAdjuster");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;