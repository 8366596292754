// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/cart/ShoppingCart.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/cart/ShoppingCart.tsx");
  import.meta.hot.lastModified = "1736334486000";
}
// REMIX HMR END

import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Link, useLocation } from '@remix-run/react';
import { CurrencyCode } from '~/generated/graphql';
import { ShoppingCartContents } from './ShoppingCartContents';
export function ShoppingCart({
  customer,
  open,
  onClose,
  activeOrder,
  adjustOrderLine,
  removeItem,
  orders,
  refreshOrders,
  statistics,
  loading
}) {
  _s();
  const currencyCode = activeOrder?.currencyCode || CurrencyCode.Chf;
  const location = useLocation();
  const editable = !location.pathname.startsWith('/checkout');
  return <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-200" onClose={onClose}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child as={Fragment} enter="transform transition ease-in-out duration-300 sm:duration-300" enterFrom="translate-x-full" enterTo="translate-x-0" leave="transform transition ease-in-out duration-300 sm:duration-300" leaveFrom="translate-x-0" leaveTo="translate-x-full">
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-bold text-black">
                        Einkaufsliste
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button type="button" className="-m-2 p-2 text-gray-400 hover:text-gray-500" onClick={() => onClose(false)}>
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>

                    <div className="mt-8">
                      {!loading && orders && orders.length > 0 ? <ShoppingCartContents customer={customer} orderLines={activeOrder?.lines ?? []} orders={orders} refreshOrders={refreshOrders} currencyCode={currencyCode} editable={editable} removeItem={removeItem} adjustOrderLine={adjustOrderLine} /> : <div className="flex items-center justify-center h-48 text-xl text-gray-400">
                          Ihr Warenkorb ist leer
                        </div>}
                    </div>
                  </div>

                  {orders && <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                      <div className="flex justify-between text-base font-bold text-gray-900">
                        <p>Total (inkl. MwSt.)</p>
                        <p>
                          <div>
                            {Number(statistics.subtotal).toLocaleString('de-CH', {
                          maximumFractionDigits: 2
                        })}{' '}
                            CHF
                          </div>
                        </p>
                      </div>
                      <div className="mt-6">
                        <Link to="/checkout" onClick={() => onClose(false)} className="flex justify-center items-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-primary-color-dark .add-to-cart-button:hover">
                          Zur Bestellung
                        </Link>
                      </div>
                    </div>}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>;
}
_s(ShoppingCart, "pkHmaVRPskBaU4tMJuJJpV42k1I=", false, function () {
  return [useLocation];
});
_c = ShoppingCart;
var _c;
$RefreshReg$(_c, "ShoppingCart");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;