// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/providers/external/pooling/pool.ts"
);
import.meta.hot.lastModified = "1735083403000";
}
// REMIX HMR END

/**
 * @file pool.ts
 * @description Uses our own (Biosonah API) logic to handle pools.
 */
import axios from 'axios';

export async function getPoolAmount(
  productOrCollectionId: string,
  isCollection = false,
) {
  const apiUrl = window.ENV.BIOSONAH_API_URL;
  try {
    const response = await axios.get(
      `${apiUrl}/pool/${isCollection ? 'collection' : 'product'}/${productOrCollectionId}`,
      {
        withCredentials: true,
      },
    );

    return response.data;
  } catch (error: any) {
    // Check if error is an AxiosError and has a response
    if (error.response && error.response.data) {
      return error.response.data;
    }

    // If no response, throw the error to be handled elsewhere
    throw error;
  }
}

export async function takeRest(productId: string) {
  const apiUrl = window.ENV.BIOSONAH_API_URL;
  try {
    const response = await axios.post(
      `${apiUrl}/pool/${productId}/take_rest`,
      {},
      {
        withCredentials: true,
      },
    );

    return response.data;
  } catch (error: any) {
    // Check if error is an AxiosError and has a response
    if (error.response && error.response.data) {
      return error.response.data;
    }

    // If no response, throw the error to be handled elsewhere
    throw error;
  }
}
